.table td,
.table th {
  overflow: hidden;
  white-space: normal !important;
}

thead {
  background-color: rgb(244, 247, 250);
}

.pcoded-header .dropdown .dropdown-toggle:after {
  display: none !important;
}

i.icon.feather.icon-settings {
  font-size: 22px !important;
}


.b-brand-logo img{
  width: 8%;
  margin-left: 3%;
}

thead {
  background-color: rgb(63 77 103);
  color: white;
}

.form-control {
  background: #f4f7fa !important;
  padding: 10px 20px !important;
  font-size: 14px !important;
  height: auto !important;
  background: #fff !important;
  box-shadow: -3px 3px 6px 0px #3f4d6757 !important;
  border: none !important;
  border-bottom: solid 1px #3f4d67 !important;
}

button.btn.btn-primary {
  box-shadow: -1px 2px 4px 1px #a1ddf9;
}

button.btn.btn-danger{
  box-shadow: -1px 2px 4px 1px #f1bcb9;
}

.table-responsive {
  /* box-shadow: 1px 1px 18px 0px #cce0f1; */
  border-radius: 5px;
}

.table {
  table-layout: fixed;
}

@media screen and (max-width: 991px) {
  .table {
    table-layout:inherit
  } 
}

.form-control:disabled{
  background-color: #ebebeb !important;
  color:#908e8e;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #47ccde;
  border-color: #47ccde;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #47ccde;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #47ccde;
  border-color: #47ccde;
  outline: none;
}

.pagination > li > a,
.pagination > li > span {
  color: #47ccde;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.global_competition_table_head{
  background-color: rgb(227,227,227) ;
  color: black;
  font-weight: 700;
}

tr.global_competition_table_row_1 {
  background: #47A5FC !important;
  color: black;
}

tr.global_competition_table_row_2 {
  background: #2BA76F !important;
  color: black;
}

tr.global_competition_table_row_3 {
  background: #FECB51 !important;
  color: black;
}

.fa-plus {
  font-size: 16px;
}


/*-------------- LeaderBoard CSS------------------*/

.leaderboard_user_image{
  width: 42px;
}

.rounded-circle{
  width: 100%;
  margin-left: 6px;
}

.leaderboard_user_name {
  display: flex;
  align-items: center;
  margin-left: 17px;
  font-weight: bold;
  font-size: 15px;
}

.leaderboard_user_name1{
  display: flex;
  align-items: center;
  margin-left: 13px;
  font-weight: bold;
  font-size: 17px;
}

.leaderboard_rank1{
      height: 58px;
      font-size: 39px;
      border-radius: 20%;
      color: #ffffff;
      box-shadow: #0000002f 3px 3px 8px 4px;
  }

.leaderboard_user_rank_1{
  background-image: linear-gradient(to right, #E9464D, #f07378);
  font-size: 17px;
  color: black;
}

.leaderboard_user_rank_2{
  background-image: linear-gradient(to right, #4665f5, #8096f7);
  /* background-color: #42a76f !important; */
  color: black;
}

.leaderboard_user_rank_3{
  background-image: linear-gradient(to right, #66b76e, #acddb1);
  color: black;
}

.leaderboard_user_rank_4{
  background-image: linear-gradient(to right, #ebc126, #fbce7f);
  color: black;
}


.leaderboard_user_rank_5{
  background-image: linear-gradient(to right, #e99ac4, #ffbbe0);
  color: black;
}

.leaderboard_data{
  padding: 0.75rem 0.75rem !important;
  font-size: 17px;
  font-weight: bold;
}

.leaderboard_data1{
  padding: 2rem 0.75rem !important;
  font-size: 17px;
  font-weight: bold;
}

.leaderboard_rank{
  width:31px;
  height: 27px;
  /* background-color: #ffffff; */
  border-radius: 20%;
  color: #ffffff;
  box-shadow: #0000002f 3px 3px 8px 4px;
  margin: 0 auto;

}
.space {
  margin-top: 2px;
}

.leaderboard_badges{
  display: flex;
  /* justify-content: space-evenly; */
}

.leaderboard_rank_text_color{
  color:black !important;
  font-weight: normal;
}

.table td {
  vertical-align: middle !important;
  color: black;
}
/* -------------------------- Datepicker --------------------------*/

.react-datepicker__input-container input{
  padding: 10px 20px;
  border-bottom: solid 1px #3f4d67 !important;
  border-top: none;
  border-left: none;
  font-size: 14px !important;
  box-shadow: -3px 3px 6px 0px #3f4d6757 !important;
  border-right: none;
  border-radius: 0.25rem;
}